"use client";
import Image from "next/image";
import { Button } from "../ui/button";
import { usePathname } from "next/navigation";
import Link from "next/link";
import MegaMenuDropdown from "../molecules/MegaMenuDropdown/MegaMenuDropdown";
import { FaArrowUp } from "react-icons/fa";
import {
  NavCard,
  NavCardFooter,
  NavCardTitle,
} from "../molecules/Card/NavCard";
import { FaArrowRight } from "react-icons/fa";
import { useEffect, useState } from "react";
import MobileMenu from "../molecules/MobileMenu/MobileMenu";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import SmartForm from "../organisms/SmartForm";
import { useGlobalStore } from "@/lib/globalStore";
import { motion } from "framer-motion";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import ScrollToTopArrow from "../molecules/ScrollToTopArrow/ScrollToTopArrow";
import { removedCategory } from "@/lib/globalFunctions";
type NavbarProps = {
  linkList: {
    items?: {
      label: string;
      link: string;
      linkLabel: string;
      imageOverlay: {
        asset: {
          url: string;
        };
      };
    }[];
    label: string;
    link: string;
  }[];
  categoryList: any;
};

const Navbar = (props: NavbarProps) => {
  const { linkList } = props;
  const [mobileMenu, setMobileMenu] = useState(false);
  const currentPage = usePathname();
  let isLight = currentPage === "/" ? false : true;

  useEffect(() => {
    document
      .getElementById("main-content")
      ?.style.setProperty("background-color", isLight ? "#F6F6F7" : "#0C1E20");
  }, [currentPage]);
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [topVisible, setTopVisible] = useState(true);
  const { width } = useWindowDimensions();

  let isDesktopXL: any;
  if (width) {
    isDesktopXL = width <= 1250;
  }
  const threshold = 150; // Define a threshold value

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (Math.abs(currentScrollPos - prevScrollPos) > threshold) {
      if (currentScrollPos > prevScrollPos) {
        setTopVisible(false);
      } else {
        setTopVisible(true);
      }

      setPrevScrollPos(currentScrollPos); // Update the previous scroll position after threshold is met
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]); // Add prevScrollPos as a dependency to useEffect

  const navAnimationVariant = {
    visible: { y: 0 },
    hidden: { y: isDesktopXL ? -112 : -112 },
  };

  // Route -> /shop/[tag]/[item]
  // URL -> /shop/shoes/nike-air-max-97
  // `params` -> { tag: 'shoes', item: 'nike-air-max-97' }
  const AllProductContent = (props: any) => {
    return (
      <div className="container relative z-[99] grid grid-cols-2 py-6">
        <div className="p-8">
          <h4 className="text-4xl font-semibold  ">Products</h4>
          <div className="py-8 border-b border-white/10 mb-8">
            <p className="text-xs font-bold text-[#828f90] mb-4">BROWSE BY</p>
            <ul className="grid grid-cols-2 gap-y-2">
              {categoryLinks
                .filter((item: any) => {
                  return !removedCategory.includes(item.name);
                })
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((category: any, key: any) => {
                  return (
                    <li key={key}>
                      <Link
                        href={category.link}
                        onClick={() => props.setIsOpen(false)}
                        className="text-xl font-semibold leading-[140%] border-b border-transparent hover:border-white transition-all"
                      >
                        {category.navigationTitle || category.name}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          <Link
            href={"/products"}
            onClick={() => props.setIsOpen(false)}
            className="flex max-w-[240px] items-center group/allproduct"
          >
            <p className="mr-auto text-xl font-semibold  border-b border-transparent group-hover/allproduct:border-white transition-all">
              All Products
            </p>
            <div className="w-9 rounded-sm h-9 flex items-center justify-center bg-main-accent">
              <FaArrowRight className="text-black text-2xl" />
            </div>
          </Link>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Link
            href={"/products/concentrated-cleaning"}
            onClick={() => props.setIsOpen(false)}
          >
            <NavCard className="min-h-[400px] ">
              <NavCardTitle>View the X-Range</NavCardTitle>
              <NavCardFooter>
                <div className="flex max-w-[240px] items-center">
                  <p className={cn("mr-auto text-xl font-semibold text-white")}>
                    See Products
                  </p>
                  <div className="w-9 rounded-sm h-9 flex items-center justify-center bg-main-accent">
                    <FaArrowRight className="text-black text-2xl" />
                  </div>
                </div>
              </NavCardFooter>
            </NavCard>
          </Link>
          <div
            onClick={() => {
              setGetStartedOpen(true);
              props.setIsOpen(false);
            }}
          >
            <NavCard className="min-h-[400px]">
              <NavCardTitle>Trial our products</NavCardTitle>
              <NavCardFooter>
                <div className="flex max-w-[240px] items-center">
                  <p className={cn("mr-auto text-xl font-semibold text-white")}>
                    Trial our products
                  </p>
                  <div className="w-9 rounded-sm h-9 flex items-center justify-center bg-main-accent">
                    <FaArrowRight className="text-black text-2xl" />
                  </div>
                </div>
              </NavCardFooter>
            </NavCard>
          </div>
        </div>
      </div>
    );
  };
  const WhyEnviroplusContent = (props: any) => {
    return (
      <div className={cn(isLight ? "bg-white" : "bg-main-dark-green ")}>
        <div className="container">
          <div className="grid relative z-[99] grid-cols-4 gap-4 py-6">
            {props.link.map((item: any, key: any) => {
              return (
                <Link
                  href={item.link}
                  key={key}
                  onClick={() => props.setIsOpen(false)}
                >
                  <NavCard
                    image={item.imageOverlay.asset.url}
                    className="min-h-[400px] "
                  >
                    <NavCardTitle>{item.label}</NavCardTitle>
                    <NavCardFooter>
                      <div className="flex max-w-[240px] items-center">
                        <p
                          className={cn(
                            "mr-auto text-xl font-semibold text-white"
                          )}
                        >
                          {item.linkLabel}
                        </p>
                        <div className="w-9 rounded-sm h-9 flex items-center justify-center bg-main-accent">
                          <FaArrowRight className="text-black text-2xl" />
                        </div>
                      </div>
                    </NavCardFooter>
                  </NavCard>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  // const [open, setOpen] = useState(false);
  const { getStartedOpen, setGetStartedOpen } = useGlobalStore();
  const categoryLinks: any = props.categoryList.map((category: any) => {
    return {
      name: category.title,
      link: `/products/${category.slug.current}`,
      navigationTitle: category.navigationTitle,
    };
  });
  return (
    <motion.div
      animate={topVisible ? "visible" : "hidden"}
      transition={{ duration: 0.3, type: "tween" }}
      variants={navAnimationVariant}
      className="sticky z-[99] top-0"
    >
      <nav
        className={cn(
          "transition-all z-[999] relative",
          isLight ? "bg-white" : "bg-main-dark-green"
        )}
      >
        {!mobileMenu && <ScrollToTopArrow />}

        <div className=" flex justify-between items-center container">
          <ul className="flex text-white w-full gap-3 items-center h-[111px] border-b border-white/10">
            <li className="mr-0 sm:mr-4">
              <Link href={"/"}>
                <Image
                  src={
                    isLight
                      ? "/enviroplus-logo-light.svg"
                      : "/enviroplus-logo-dark.svg"
                  }
                  width={191}
                  height={37}
                  alt="Enviroplus logo"
                  className="cursor-pointer transition-all "
                />
              </Link>
            </li>
            <li className="hidden h-full xs:flex flex-col  items-center justify-center group mr-auto ">
              <div className="h-[6px] rounded-bl-md rounded-br-md w-full bg-main-accent opacity-0 group-hover:opacity-100 transition-all"></div>
              <div className="hidden nav-breakpoint:block h-full">
                <MegaMenuDropdown isLight={isLight} label={"Products"}>
                  <AllProductContent />
                </MegaMenuDropdown>
              </div>
            </li>
            <li className="h-full hidden nav-breakpoint:block">
              <ul className="flex  items-center h-full px-4">
                {linkList.map((link, key) => {
                  return (
                    <li
                      className="h-full flex flex-col items-center justify-center  group mr-auto "
                      key={key}
                    >
                      <div
                        className={cn(
                          `h-[6px] rounded-bl-md rounded-br-md w-full bg-main-accent opacity-0 group-hover:opacity-100 transition-all`,
                          currentPage.startsWith(link.link) && "opacity-100"
                        )}
                      ></div>
                      {link.items ? (
                        <MegaMenuDropdown isLight={isLight} label={link.label}>
                          <WhyEnviroplusContent link={link.items} />
                        </MegaMenuDropdown>
                      ) : (
                        <Link
                          className={cn(
                            "h-full flex items-center font-semibold justify-center mx-4 whitespace-nowrap",
                            isLight ? "text-main-dark-green" : "text-white"
                          )}
                          href={link.link}
                        >
                          {link.label}
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </li>
            <li>
              <div>
                <Dialog open={getStartedOpen} onOpenChange={setGetStartedOpen}>
                  <DialogTrigger asChild>
                    <Button variant={isLight ? "primary" : "primary-light"}>
                      Get Started
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="w-full h-full  !z-[99999] !border-none !bg-main-dark-green max-w-none !rounded-none ">
                    <SmartForm onOpenChange={setGetStartedOpen} />
                  </DialogContent>
                </Dialog>
              </div>
            </li>
            <li className="block nav-breakpoint:hidden">
              <div
                className={`cursor-pointer w-10 xs:w-14 h-14 flex items-center relative justify-center hover:bg-brand-accent transition-all ${
                  mobileMenu ? "bg-brand-accent" : "bg-transparent"
                }`}
                onClick={() => setMobileMenu(!mobileMenu)}
              >
                <div className="block w-5 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
                  <span
                    aria-hidden="true"
                    className={`block absolute h-0.5 w-6 bg-current transform transition duration-150 ease-in-out ${
                      mobileMenu ? "rotate-45" : "-translate-y-1.5"
                    } ${isLight ? "bg-main-dark-green" : "bg-white"}`}
                  ></span>

                  <span
                    aria-hidden="true"
                    className={`block absolute  h-0.5 w-6 bg-current transform  transition duration-150 ease-in-out ${
                      mobileMenu ? "-rotate-45" : "translate-y-1.5"
                    } ${isLight ? "bg-main-dark-green" : "bg-white"}`}
                  ></span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        {isVisible && (
          <div
            className="fixed w-[50px] group top-24 aspect-square right-0 flex items-center justify-center bg-main-dark-green-light text-white rounded-l-2xl cursor-pointer hover: transition-all z-[999]"
            onClick={scrollToTop}
          >
            <FaArrowUp
              size={24}
              className="group-hover:text-main-accent transition-all"
            />
          </div>
        )}

        <MobileMenu
          setMobileMenu={setMobileMenu}
          linkList={linkList}
          categoryLinks={categoryLinks}
          isMobileMenuOpen={mobileMenu}
        />
      </nav>
    </motion.div>
  );
};

export default Navbar;
